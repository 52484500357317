<i18n locale="th" lang="yaml" >
ticket.comment.topic : "Comment ของ Ticket"
ticket.link : "ดูรายละเอียดเพิ่ม"
ticket.comment : "Comment"
ticket.comment.description : "เพิ่ม Comment"
</i18n>

<template>
    <div class="drone-comment-ticket-history">
        <div class="topic">
			<MyTooltip trigger="click|hover" display-mode="white" @click.stop>
				<div slot="title" class="ticket-comment-tooltip">
					<div class="description">
						<CommentIcon /> {{$t('ticket.comment.description')}}
					</div>
				</div>
				<span class="comment-tag">
					{{$t('ticket.comment')}}
				</span>
			</MyTooltip>
			{{$t('ticket.comment.topic')}} {{history.data.ticket_no}}
            <a-dropdown :trigger="['click']">
				<a class="ant-dropdown-link" @click.prevent>
					<DotsVerticalIcon/>
				</a>
				<template v-slot:overlay>
					<a-menu>
						<a-menu-item key="0">
							<my-router-link name="ticket/view" :param-value="history.data.ticket_id">
								{{$t('ticket.link')}}
							</my-router-link>
						</a-menu-item>
					</a-menu>
				</template>
  			</a-dropdown>
		</div>

        <span class="action-date">{{this.$dayjs(history.data.created_datetime).format("D MMMM YYYY HH:mm")}}</span>

        <div class="detail" v-html="history.data.detail_rich"></div>
        <HistoryActionBy :user="history.actionBy.user" :company="history.actionBy.company" :action-time="history.data.created_datetime"/>
    </div>
</template>
<script>
import HistoryActionBy from './HistoryActionByText.vue';
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import CommentIcon from "vue-material-design-icons/CommentOutline.vue"

export default {
    components: {
        HistoryActionBy,
        DotsVerticalIcon,
		MyTooltip,
		CommentIcon
    },
    props: {
        history: {
            type: null,
            default: () => []
        },
    },

}
</script>

<style lang="less" scoped>
	.drone-comment-ticket-history {
		.topic {
			font-family : @font-family-title;
			color : @error-color;
		}
		.action-date {
			color : @info-color;
			margin-left : 8px;
			font-size : 0.90em;
			font-weight: normal;
		}
		.detail {
			margin-top : 8px;
			margin-left : 16px;
		}

	}
	.comment-tag{
		font-weight: normal;
		font-family: @font-family;
		white-space: nowrap;
		display : inline-block;
		padding : 0 7px;
		border-radius : @border-radius-base;
		font-size : 12px;
		line-height: 20px;
		color : white;
		background : @text-muted;
	}

	.ticket-comment-tooltip {
		padding: 2px 4px;
		.description{
		color: @text-muted;
	}
}

</style>
