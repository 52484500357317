<template>
	<div class="drone-log-history">
		<div class="topic">{{log.action_label}}</div>
		<span class="action-date">{{actionDatetime}}</span>
		<div class="detail">
			<div>{{log.detail}}</div>
			<HistoryActionBy 
				:user="actionBy.user" 
				:company="actionBy.company" 
				:action-time="log.created_datetime"/>
		</div>
	</div>
</template>

<script>
import HistoryActionBy from "./HistoryActionByText.vue"
export default {
	components : {
    HistoryActionBy
} ,
	props : {
		history : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		actionBy() {
			return this.history.actionBy
		} ,
		log() {
			return this.history.data
		},
		actionDatetime() {
			return this.$dayjs(this.history.data.created_datetime).format("D MMMM YYYY HH:mm");
		}
	}
}
</script>

<style lang="less" scoped>
.drone-log-history {
	.topic {
		font-family : @font-family-title;
		color : @primary-color;
	}
	.action-date {
		color : @info-color;
		margin-left : 8px;
		font-size : 0.90em;
		font-weight: normal;
	}
	.detail {
		margin-top : 8px;
		margin-left : 16px;
		font-size : 0.95em;
	}
}
</style>
