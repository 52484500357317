<i18n locale="th" lang="yaml" >
page.title : "{name} :: ประวัติ"
page.header : "ประวัติการเปลี่ยนแปลงของ Drone ตัวนี้"
page.description : "รายการการเปลี่ยนแปลงทั้งหมดของ Drone ตัวนี้"

histories.show.tickets : "แสดง Ticket"
histories.show.services : "แสดงงานซ่อม"

history.all: "ประวัติทั้งหมด"
history.selected: "งานที่เกี่ยวข้องกับ {serviceNo}"
</i18n>

<template>
	<div class="page">
		<div class="page-action-right">
			<a-button icon="left" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<my-page-header
		:title="$t('page.header',{ name: drone.serial_no })"
		:description="$t('page.description',{ name: drone.serial_no })"/>

		<a-card :bordered="false" :loading="loading">
			<div class="drone-timeline-parent">
				<a-timeline class="drone-history-timeline">
					<div class="timeline-title">{{$t('history.all')}}</div>
					<div class="histories-filter">
						<a-checkbox v-model="showTickets">
							{{ $t('histories.show.tickets')}}
						</a-checkbox>
						<a-checkbox v-model="showServices">
							{{ $t('histories.show.services')}}
						</a-checkbox>
					</div>
					<DroneHistoryTimelineItem
						v-for="history in histories" :key="history.key"
						:history="history"
						@open-relate-timeline="openRelateTimeline" />
				</a-timeline>
				<a-timeline v-show="isOpenRelateTimeline" class="drone-relate-history-timeline" :pending="relateLoading">
					<div class="related-timeline-title">
						<div>{{$t('history.selected', { serviceNo: relatedServiceNo })}}</div>
						<div>
							<CloseIcon class="close-icon" @click="closeRelatedTimeline"/>
						</div>
					</div>

					<div class="histories-filter">
						<a-checkbox v-model="showRelatedTickets">
							{{ $t('histories.show.tickets')}}
						</a-checkbox>
						<a-checkbox v-model="showRelatedServices">
							{{ $t('histories.show.services')}}
						</a-checkbox>
					</div>
					<DroneHistoryTimelineItem v-for="history in relateHistories" :key="history.key"
						:history="history" :is-relate-timeline="true" />
				</a-timeline>
			</div>
		</a-card>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import {fulfillDroneHistory,emptyDrone} from "@utils/objectUtil"
import {Timeline,Checkbox} from "ant-design-vue"
import DroneHistoryTimelineItem from "@components/drone/history/DroneHistoryTimelineItem.vue"
import axios from "axios"
import CloseIcon from "vue-material-design-icons/CloseCircleOutline.vue"


export default {
	components : {
		"a-timeline" : Timeline ,
		"a-checkbox" : Checkbox,
		DroneHistoryTimelineItem,
		CloseIcon
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name : this.drone.serial_no})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		} ,
	} ,
	data() {
		return {
			loading : false,
			historyList : [] ,
			currentDroneId : undefined ,
			showTickets : false,
			showServices : true,

			showRelatedServices: true,
			showRelatedTickets: false,

			isOpenRelateTimeline: false,
			relatedServiceNo: null,
			relatedServiceId: null,
			relateTimelineList: [],
			relateLoading: false,

		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		histories() {
			return this.historyList.filter((h) => {
				switch(h.type){
					case 'ticket':
					case 'ticket_comment':
						return this.showTickets
					case 'service':
						return this.showServices
					default:
						return true;
				}
			})
		},
		relateHistories() {
			return this.relateTimelineList.filter((h) => {
				switch(h.type){
					case 'ticket':
					case 'ticket_comment':
						return this.showRelatedTickets
					case 'service':
					case 'service_refer':
						return this.showRelatedServices
					default:
						return true;
				}
			})
		},
	} ,
	watch : {
		droneData : {
			handler() {
				this.fetchData()
			} ,
			deep : true
		} ,

	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			if (!this.drone.id || (this.currentDroneId && this.currentDroneId === this.drone.id))
				return

			this.loading = true
			axios.get(`/api/drones/${this.drone.id}/history`).then((response) => {
				const bucket = response.data.data.bucket
				this.currentDroneId = this.drone.id
				this.historyList = response.data.data.histories.map((history) => {
					return fulfillDroneHistory(history,bucket)
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.loading = false
			})
		} ,
		goBack() {
			this.$open({name : 'drone/view' ,params : {serial_no : this.drone.serialNo}})
		},
		closeRelatedTimeline(){
			this.isOpenRelateTimeline = false;
		},
		openRelateTimeline(serviceId) {
			if(serviceId !== this.relatedServiceId) {
				this.relateLoading = true
				this.isOpenRelateTimeline = true
				this.relateTimelineList =  []
				this.relatedServiceNo = '...'
				this.showRelatedServices = true;
				this.showRelatedTickets = false;
				this.scrollToTop()
				axios.get(`/api/services/${serviceId}/related-history`)
					.then((response) => {
						const data = response.data.data;
						const latestServiceNo = data.histories.filter(his=>his.type==='service')[0].data.service_no;
						const bucket = data.bucket
						this.relateTimelineList = data.histories.map((history) => {
							return fulfillDroneHistory(history, bucket)
						})

						this.relatedServiceNo = latestServiceNo
						this.relatedServiceId = serviceId
					})
					.catch((error) => {
						this.fetchError(error)
					})
					.finally(() => {
						this.relateLoading = false

					})
			}
			else{
				this.isOpenRelateTimeline = true
			}
		},
		scrollToTop() {
			document.getElementById('tunjai_layout_content').scrollTo({
				top: 0,
				left: 0
			});
		}
	},
}
</script>

<style lang="less" scoped>
.drone-timeline-parent{
	display: flex;
}
.drone-history-timeline::v-deep,
.drone-relate-history-timeline::v-deep {
	padding: 16px 16px;
	max-width: 450px;
	width: 50%;
	.timeline-title{
		font-size: 20px;
		margin-bottom: 5px;
	}
	.related-timeline-title{
		display: flex;
		font-size: 20px;
		margin-bottom: 5px;
		.close-icon {
			cursor: pointer;
			color:red;
			margin-left: 5px;
		}
	}
	.histories-filter {
		margin-bottom: 16px;
		label {
			margin-left: unset;
		}
	}

	.mobile & {
		padding: 16px 0;
	}

	.ant-timeline-item-content {
		margin-left: 24px;
	}
}
</style>
