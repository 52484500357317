<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>

<template>
	<div class="info image-text-tiny">
		<span>
			{{ $t('common.by') }}
		</span>
		<my-router-link name="user/view" :param-value="user.id" :new-window="true">
			{{ user.username }}
		</my-router-link>
		<span>
			{{ $t('common.from') }}
		</span>
		<my-router-link name="company/view" :param-value="company.id" :new-window="true" auth-action="read" auth-module="company">
			<ImageName :name="company.name" :src="company.image_url" class="company" />
		</my-router-link>
		<DayFromNowText class="info-time" :prefix="$t('common.since')" :value="actionTime" />
	</div>
</template>

<script>
import ImageName from '@components/common/ImageName.vue';
import DayFromNowText from '@components/common/DayFromNowText.vue';
export default {
	components: {
		ImageName,
		DayFromNowText
	},
	props: {
		user: {
			type: null,
			default: () => [],
		},
		company: {
			type: null,
			default: () => [],
		},
        actionTime:{
            type: null,
			default: () => [],
        }
	}
}
</script>

<style lang="less" scoped>
	.info {
		color : @text-muted;
		padding-top : 12px;
		font-size : 0.9em;
	}
	.info-time{
		vertical-align: middle;
	}
</style>
