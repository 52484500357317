<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
service.topic : "งานซ่อม {serviceNo}"
service.open_by : "รับเรื่องโดย"
service.jobs_detail.unspecify : "ยังไม่ระบุงาน"
service.is_device_only : "งานซ่อมที่ไม่ได้ใช้ Drone"
service.status.repair : "การซ่อม"
service.status.payment : "การชำระเงิน"
service.status.claim : "เคลม"
service.link : "ดูรายละเอียดเพิ่ม"
service.relate.link: "Timeline ที่เกี่ยวข้อง"
service.claim_no : "ใบเคลม {value}"
service.status.reason: 'สาเหตุ'
</i18n>

<template>
	<div class="drone-service-history">
		<div class="topic">
			<ServiceStatus :status="service.status"/>
			{{$t('service.topic',{ serviceNo: service.service_no })}}

			<a-dropdown :trigger="['click']">
				<a class="ant-dropdown-link" @click.prevent>
					<DotsVerticalIcon/>
				</a>
				<template v-slot:overlay>
					<a-menu>
						<a-menu-item key="0">
							<my-router-link v-if="canViewDetail" name="service/view" :param-value="service.id">
								{{$t('service.link')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item v-if="showRelateLink" key="1" @click="openRelateTimeline">
							<span>
								{{$t('service.relate.link')}}
							</span>
						</a-menu-item>
					</a-menu>
				</template>
  			</a-dropdown>
		</div>
		<span class="action-date">{{actionDatetime}}</span>
		<div class="detail">

			<div v-if="$notEmpty(service.status_reason)">
				<span>{{$t('service.status.reason')}}</span>:
				<span >{{service.status_reason}}</span>
			</div>

			<div class="service-jobs">
				{{jobsDetail}}
			</div>

			<div class="service-status-list">
				<div v-if="repairStatus != null" class="row">
					<label>{{$t('service.status.repair')}}</label>
					<StatusDot class="value"
						:status="$tenum('service.repair.result',repairStatus.result)"
						:reason="repairStatus.reason"
						:icon-mode="repairStatusDotMode" />
				</div>
				<div v-if="paymentStatus != null" class="row">
					<label>{{$t('service.status.payment')}}</label>
					<StatusDot class="value"
						:status="$tenum('service.payment.status',paymentStatus.status)"
						:icon-mode="paymentStatusDotMode" />
				</div>
				<div v-if="claimStatus != null" class="row">
					<label>{{$t('service.status.claim')}}</label>
					<StatusDot class="value"
						:status="$tenum('claim.status',claimStatus)"
						:description ="$tenum('claim.status',claimStatus + '.description')"
						:icon-mode="claimStatusDotMode" />
				</div>
			</div>
			<div v-if="$notEmpty(claimNo)" class="service-claim">
				<my-router-link name="service/claim" :param-value="service.id" >
					{{$t('service.claim_no',{ value: claimNo })}}
				</my-router-link>
			</div>


			<HistoryActionBy :user="history.actionBy.user" :company="history.actionBy.company" :action-time="service.updated_datetime"/>
		</div>

	</div>
</template>

<script>
import StatusDot from "@components/common/StatusDot.vue"
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue"
import _get from "lodash/get"
import ServiceStatus from "@components/service/ServiceStatus.vue"
import {getServiceJobsList,lookupStatusDotMode,isStatusAllowView} from "@utils/serviceUtil"
import HistoryActionBy from "./HistoryActionByText.vue"
export default {
	components : {
		ServiceStatus,
		DotsVerticalIcon,
		StatusDot,
		HistoryActionBy
	} ,
	props : {
		history : {
			type : null,
			default : () => []
		},
		showRelateLink: {
			type: Boolean,
			default: true
		}
	} ,
	computed : {
		actionBy() {
			return this.history.actionBy
		} ,
		service() {
			return this.history.data
		} ,
		company() {
			return this.actionBy.company
		} ,
		jobsDetail(){
			const list = getServiceJobsList(this,this.service)
			let detailDisplay = (list.length == 0) ? this.$t('service.jobs_detail.unspecify') : list.join(', ')
			if (this.service.is_device_only) {
				detailDisplay += " ["+this.$t('service.is_device_only')+"]"
			}
			return detailDisplay
		} ,
		canViewDetail() {
			if (this.$authorize('read','service',{companyId : this.service.company_id}))
				return true
			else
				return isStatusAllowView('details',this.service.status)
		} ,
		paymentStatus() {
			return this.service.otherDetailsData.payment_status
		} ,
		repairStatus() {
			return this.service.otherDetailsData.repair_status
		} ,
		claimStatus() {
			return _get(this.service,'otherDetailsData.claim_request.status')
		} ,
		claimNo() {
			return _get(this.service,'otherDetailsData.claim_request.claim_no')
		} ,
		paymentStatusDotMode() {
			return this.paymentStatus != null ? lookupStatusDotMode('payment',this.paymentStatus.status) : null
		} ,
		repairStatusDotMode() {
			return this.repairStatus != null ? lookupStatusDotMode('repair',this.repairStatus.result) : null
		} ,
		claimStatusDotMode() {
			return lookupStatusDotMode('claim',this.claimStatus)
		},
		actionDatetime() {
			return this.$dayjs(this.service.updated_datetime).format("D MMMM YYYY HH:mm");
		},
	},
	methods:{
		openRelateTimeline() {
			this.$emit('open-relate-timeline', this.service.id);
		}
	}
}
</script>

<style lang="less" scoped>
.drone-service-history {
	.topic {
		font-family : @font-family-title;
	}
	.action-date {
		color : @info-color;
		margin-left : 8px;
		font-size : 0.90em;
		font-weight: normal;
	}
	.detail {
		margin-top : 8px;
		margin-left : 16px;
	}
	.service-claim {
		margin-top : 16px;
		text-decoration: underline dashed;
	}
	.service-jobs {
		margin-bottom : 6px;
		color : @primary-color;
		font-size : 14px;
	}
	.service-status-list {
		font-size : 0.9em;
		label {
			width : 75px;
			display: inline-block;
			&::after {
				content : ' :';
			}
		}
	}
}

</style>
