<i18n locale="th" lang="yaml" >
ticket.topic : "พบปัญหาจาก Ticket"
ticket.link : "ดูรายละเอียดเพิ่ม"
ticket.report_by : "รายงานโดย"
ticket.resolved_result : "ผลการประเมิน"
ticket.reason :  "ผลการวิเคราะห์"
</i18n>

<template>
	<div class="drone-ticket-history">
		<div class="topic">
			<TicketStatus :status="ticket.status" :tag-style="true" />
			{{$t('ticket.topic')}} {{ticket.ticket_no}}
			<a-dropdown :trigger="['click']">
				<a class="ant-dropdown-link" @click.prevent>
					<DotsVerticalIcon/>
				</a>
				<template v-slot:overlay>
					<a-menu>
						<a-menu-item key="0">
							<my-router-link name="ticket/view" :param-value="ticket.id">
								{{$t('ticket.link')}}
							</my-router-link>
						</a-menu-item>
					</a-menu>
				</template>
  			</a-dropdown>
		</div>
		<span class="action-date">{{actionDatetime}}</span>
		<div class="detail">
			<div>
				{{ticket.topic}}
				<div v-if="canShowResult" class="history-result-detail">
					<div>
						<span class="title">{{$t('ticket.resolved_result')}}</span> : {{$tenum('ticket.resolved_result',ticket.resolved_result)}}
					</div>
					<div>
						<span class="title">{{$t('ticket.reason')}}</span> : {{ticket.status_reason}}
					</div>
				</div>
				
			</div>
			<HistoryActionBy :user="actionBy.user" :company="company" :action-time="ticket.event_datetime"/>
		</div>
	</div>
</template>

<script>
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue"
import TicketStatus from "@components/ticket/TicketStatus.vue"
import HistoryActionBy from "./HistoryActionByText.vue"
export default {
	components : {
    	TicketStatus,
    	HistoryActionBy,
		DotsVerticalIcon
	} ,
	props : {
		history : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		actionBy() {
			return this.history.actionBy
		} ,
		ticket() {
			return this.history.data
		} ,
		company() {
			return this.actionBy.company
		} ,
		canShowResult() {
			return this.ticket.problem_type == 'consult' && this.$notEmpty(this.ticket.resolved_result)
		},
		actionDatetime() {
			return this.$dayjs(this.ticket.event_datetime).format("D MMMM YYYY HH:mm");
		}
	}
}
</script>

<style lang="less" scoped>
.drone-ticket-history {
	.topic {
		font-family : @font-family-title;
		color : @error-color;
	}
	.action-date {
		color : @info-color;
		margin-left : 8px;
		font-size : 0.90em;
		font-weight: normal;
	}
	.detail {
		margin-top : 8px;
		margin-left : 16px;
		font-size : 0.95em;
	}
	.history-result-detail {
		margin-top : 12px;
		font-size : 0.95em;
		padding : 8px;
		border : 1px dashed @border-color-base;
		border-radius : @border-radius-base;
		> div {
			margin-bottom : 2px;
		}
		.title {
			text-decoration: underline;
		}
	}
}
</style>
